import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/regulation/*" />
    <RAC path="/zh-cn/regulation/*" />
    <RAC path="/zh-tw/regulation/*" />
    <RAC path="/pl/regulation/*" />
    <RAC path="/ru/regulation/*" />
    <RAC path="/it/regulation/*" />
    <RAC path="/de/regulation/*" />
    <RAC path="/es/regulation/*" />
    <RAC path="/fr/regulation/*" />
    <RAC path="/ar/regulation/*" />
    <RAC path="/id/regulation/*" />
    <RAC path="/pt/regulation/*" />
    <RAC path="/ko/regulation/*" />
    <DynamicNotFoundPage default />
  </Router>
)
